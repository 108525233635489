import React, { useEffect, useState } from "react";
import { Octokit } from "@octokit/rest";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";

function App() {
    const [gitList, setGitList] = useState([]);
    const columns = [
        {
            field: "name",
            headerName: "レポジトリ名",
            width: 200,
        },
        {
            field: "pushed_at",
            headerName: "最終更新日時",
            width: 250,
        },
        {
            field: "html_url",
            headerName: "URL",
            width: 450,
        },
        {
            field: "clone_url",
            headerName: "Clone URL",
            width: 450,
        },
    ];
    useEffect(() => {
        const octokit = new Octokit({
            auth: "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"
        });
        const list = async () =>
            await octokit.repos
                .listForOrg({
                    org: "onesec-group",
                    type: "private",
                    sort: "popularity",
                    direction: 'desc',
                    per_page: 50,
                })
                .then((response) => {
                    response.data.map((res) => {
                      res.pushed_at = new Date((res.pushed_at)).toLocaleString()
                    });
                    console.log(response)
                    setGitList(response.data.sort((a, b) => a.pushed_at < b.pushed_at ? 1 : -1));
                });
        list();
    }, []);

    return (
        <div className="App">
            <Alert severity="info">GitHub レポジトリー履歴</Alert>
            <Box sx={{ height: '90vh', width: "100%" }}>
                <DataGrid
                    rows={gitList}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Box>
        </div>
    );
}

export default App;
